.shade1 {
    background: #000000d1;
}
.alert.cus-alert {
    border: none !important;
    border-radius: none !important;
    background: url(https://cgrdc.co.in/vhub/assets/images/users/DRY-VAN-stock.webp) no-repeat center top;
    padding: 0px !important;
    margin: 0px -5px !important;
    color: #fff;
    background-size: 100% 100% !important;
    border-radius: 0px;
}
.shade1 h5 {
    font-weight: 500;
    font-size: 19px !important;
}
.shade1 .fa.fa-times {
    border: 1px solid #fff;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 0 0 10px;
    color: ;
    cursor: pointer;
}
.searchnav {
    display: inline-block;
}
.fa.fa-map-o:before {
    content: "\f279";
}
.se-new .nav-tabs .nav-item i {
    width: 25px;
    text-align: center;
}
.fa.fa-map-o {
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
}
.se-new .nav-tabs .nav-link.active {
    background: #e31b1a !important;
    color: #fff !important;
    border-radius: 0px;
}
.se-new .border {
    border-top: 4px solid #404e67 !important;
    border-bottom: 4px solid #404e67 !important;
    border-left: 3px solid #404e67 !important;
    border-right: 3px solid #404e67 !important;
}
.container1 {
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: auto;
    margin-left: auto;
}
.icon-size label {
    color: #7d7d7d;
}
.pl-1, .px-1 {
    padding-left: 0.25rem!important;
}
.pr-1, .px-1 {
    padding-right: 0.25rem!important;
}
.icon-size i {
    font-size: 15px;
    color: #e31b1a;
    vertical-align: middle;
    border-right: 1px solid #000;
    width: 20px;
}
.dated-i1 input {
    background: transparent !important;
    border: none;
    font-size: 14px;
    font-weight: 400;
    padding: 1px 0px 1px 5px;
    margin: 5px 0 0 0;
    width: 98%;
    border-radius: 0px;
    height: 25px;
    text-align: left;
    color: #000;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.fa.fa-map-marker:before {
    content: "\f3c5";
}
.dated-i2 input {
    background: transparent !important;
    border: none;
    font-size: 14px;
    font-weight: 400;
    padding: 1px 0px 1px 5px;
    margin: 5px 0 0 0;
    width: 96%;
    border-radius: 0px;
    height: 25px;
    text-align: left;
    color: #000;
    border-radius: 5px;
}
.mdi-trending-up:before {
    content: "\F535";
}
.dated-inum input {
    background: transparent !important;
    border: none;
    font-size: 15px;
    padding: 1px 0px 1px 0px;
    margin: 5px 0 0 0;
    width: 50px;
    border-radius: 0;
    height: 24px;
    text-align: center;
    border-radius: 5px;
    color: #000;
}

.position2 h6 span, .modal-footer1 h6 span {
    font-weight: 400;
    font-size: 80%;
    /*display: block;*/
    margin: 0 auto 3px;
}
.responsive-map  {
    overflow: hidden;
   /* padding-bottom: 20.25%;*/
    position: relative;
    height: 0;
    background-color: #000;
}
.responsive-map iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.7;
}
.navbuttons .dropdown-toggle::after {
    /* display: inline-block; */
    margin-left: 10px;
    vertical-align: 0;
    content: "+";
    border: 0;
}
.mdi-grid:before {
    content: "\F2C1";
}
.fa.fa-map-marker:before {
    content: "\f3c5";
}
.location1 .fa.fa-map-marker::after {
    content: " ";
    width: 1px;
    height: 50px;
    display: block;
    border-radius: ;
    border-right: 2px dotted #000;
    padding-left: 3px;
}
.custom-container .main-content {
    width: 100%;
    height: 50%;
    transition: 1s ease;
    z-index: 99;
}
.custom-container .sidebar {
    width: 100%;
    /*height: 50%;*/
    /* transition: 1s ease; */
    position: relative;
}
.custom-container .main-content_large {
    width: 100%;
    height: 100%;
}
.custom-container .sidebar_small {
    width: 100%;
    height: 0px;
    overflow: hidden;
    flex-basis: 0% !important;
}

#one, #two {
    display: inline;
}
.btn-group-sm > .btn, .btn-sm {
    padding: 0.25rem 0.35rem;
    font-size: 12px;
}
.btn-secondary1 {
    background: #e9e9ed !important;
    color: #000 !important;
}
.dropdown-toggle {
    white-space: nowrap;
}
#RepositioningTab .searchbtn a {
    padding-top: 26%;
}
.position {
    position: relative;
    width: 100% !important;
    z-index: 99;
    margin-top: 0px !important;
    padding: 5px 0px;
    /*border:1px solid #e2dede;
    background-color: #000000;*/
}
.position2 {
   /* position: absolute;*/
    z-index: 99;
    width: 100%;
    height: 40px;
    padding-top: 0px;
}
.box-shadow{
    box-shadow: none !important;
}

.box-height{
    height: 28px;
    font-size: 13px;
    font-weight: bold;
    padding-top: 3px !important;
}

.drop-down-show{
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(0px, -390px, 0px);
}
.date-align{
    text-align: left !important;
    padding-left: 30px;
}
.date2-align{
    padding-left: 0px !important;
    margin-left: -50px;
}
.select-list {
    padding: 0px;
     list-style: none;
}
#search2 .responsive-map {
    padding-bottom: 100%;
}
.res:hover,.res:active,.res:visited,.res:focus{
    background-color: #e9ecee;
}

.resactive{
    background-color: #e9ecee;
    border: 2px solid #00cfb4;

}
.navborder{
    /*border: 1px solid #FFF !important;*/
    padding: 5px;
}
.book-button{
    background-color: #3cb196 !important;
    border-radius: 5px;
    border-color: #3cb196;
    cursor: pointer;
}
.available-count{
    background: #e0e4e4;
    /* height: 20px; */
    /* margin: 10px; */
    margin-bottom: 0px;
    padding: 5px;
    margin-top:5px;
    /*margin-left: 20px;
    padding-left: 10px;*/
    border-radius: 10px;
    padding-right: 10px;
    color: #000;
    font-weight: 600;
    font-size: 9px;
    /* padding-top: 5px;*/
}
.nav-tabs {
    border: 1px solid #dee2e6;
    border-radius: 5px;
}
.searchnav .nav-item {
    display: inline-block;
}
.searchbtn {
    background: #e31b1a;
    height: 100%;
    line-height: 120px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    /*padding-top: 40%;*/
}
.gmap-up-height{
    height: 75vh;
}
.gmap-down-height{
    height: 50vh;
}
.dropdown-menu.exceed2.show {
    min-width: 350px;
    padding: 5px 15px 15px;
}
.custom-container button.cus {
    /* position: absolute; */
    /* border: none; */
    /* height: 30px; */
    width: 30px;
    /* right: -17px; */
    /* transform: translateX(-50%); */
    cursor: pointer;
    z-index: 99;
    background: transparent;
    font-size: 20px;
    /* top: 0; */
    color: #fff !important;
    height: 100%;
    border: none !important;
}
.cus i {
    background: #000;
    /* padding: 20px; */
    width: 30px;
    height: 30px;
    line-height: 30px;
}
.main-content .position .cus .fa.fa-angle-double-up {
    display: none;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
}
.dropdown-menu.exceed1.show {
    min-width: 400px;
    padding: 5px 15px 15px;
}
.show > .dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0 !important;
}
.dropdown-menu.show {
    display: block;
}

.equip-type-show{
    position: absolute;
    transform: translate3d(0px, -380px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
}

.modal-year-show{
    position: absolute;
    transform: translate3d(0px, -150px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
}

.res:hover{
    /*background-color: #9ea5ab;*/
}
.bottom-panel{
    display:flex;
    justify-content:center;
    padding-left:3px;
    padding-right:3px;
    padding-bottom: 3px;
    background-color:#FFF;
    border:1px solid #f5ebeb
}
.tile-font-size{
    font-size: 10px;
}
.sidebar{
    height: 192px;
    overflow-y:auto;
    overflow-x:hidden;
}
.sidebarPublic{
    height: 250px;
    overflow-y:auto;
    overflow-x:hidden;
}
.res{
    margin:0px;
    padding:10px 12px;
    cursor:pointer;
    height:280px
}
.sel-tile-button{
    background-color: #FFF;
    color: #000;
    border: 1px solid;
}
.gm-style{
    font-family: Montserrat, sans-serif;
    font-size: 13px;
}
.gm-style .gm-style-iw{
    max-height: 350px !important;
    height:auto;
}
.gm-style-iw-d{
    max-height: 350px !important;
}
@media (min-height: 800px){
    .sidebar{
        height: 225px;
    }
}
@media (min-height: 930px){
    .sidebar{
        height: 275px;
    }
}
@media (min-height: 990px){
    .sidebar{
        height: 305px;
    }
}
@media (min-width: 1200px) {
    .col-xl-3 {
        flex: 0 0 auto;
       /* width: 20% !important;*/
    }
}

@media (min-width: 992px){
    .dated-i2 input, .dated-inum {
        /*text-align: center !important;*/
    }
    .modal-footer1 {
        position: absolute;
        width: 118%;
        padding: 0px;
        margin: 0px;
        z-index: 9;
        left: -19%;
    }
    .height50 {
        height: 60px;
    }
    .half-circle {
        background: #e31b1a;
        height: 100px;
        border-radius: 0 0 100px 100px;
        position: relative;
        width: 200px;
        color: #fff;
        border: 2px solid #fff;
        margin: 0 auto;
        font-size: 26px;
        z-index: 9;
    }

}
