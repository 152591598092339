.custom-form-switch {
    width: 50px !important;
    height: 25px !important;
}

input[type='checkbox']:enabled:checked {
    background-color: #0c63e4 !important;
    color: white;
}

.form-switch input:checked ~ .checkmark {
    background-color: indianred !important;
}

.slider {
    border-radius: 34px;
}

.customer-profile-name {
    margin-right: 10px;
}