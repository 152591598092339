/*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css');

/* body {
    background: #EEF0F2;
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px !important;
    font-weight: 400;
} */

.wrapper{
    display: flex;
    flex-direction: column;
    height:100%
}
.header{
    font-family: 'Times New Roman', Times, serif;
    font-size: 12px;
    margin-bottom: 5px;
}



#search-db a {
    text-decoration: none;
    color: #111;
}

#search-db a:hover {
    color: #E21E1E;
}

#search-db input {
    /* border: 0.5px solid #ccc;
    border-radius: 8px;
    padding: 10px; */

}

#search-db .input-group {
    flex-wrap: inherit;
}

#search-db .input-group .input-group-text{
    background-color: transparent;
}

#search-db .input-group-text {
    font-size: 12px;
}

#search-db .fs-8 {
    font-size: 8px !important;
}

#search-db .fs-9 {
    font-size: 9px !important;
}

#search-db .fs-10 {
    font-size: 10px !important;
}

#search-db .fs-12 {
    font-size: 12px !important;
}

#search-db .fw-400 {
    font-weight: 400 !important;
}

#search-db .fw-500 {
    font-weight: 500 !important;
}

#search-db .fw-600 {
    font-weight: 600 !important;
}

#search-db .text-fade {
    color: #000000 !important;
    font-weight: 500 !important;
}

#search-db .text-red {
    color: #E10909;
}

#search-db .text-green {
    color: #00B97C;
}

#search-db .checked {
    color: #FCD000;
}

#search-db .m-t-1 {
    margin-top: 1px;
}

#search-db .m-t-2 {
    margin-top: 2px;
}

#search-db .m-b-1 {
    margin-bottom: 1px;
}

#search-db .m-b-2 {
    margin-bottom: 2px;
}

#search-db .m-r-2 {
    margin-right: 2px;
}

#search-db .m-r-4 {
    margin-right: 4px;
}

#search-db .m-r-6 {
    margin-right: 6px;
}

#search-db .me-05 {
    margin-right: 0.5rem !important;
}

#search-db .c-click {
    color: #5F5F5F;
}

/* GLOBAL COLOURS */
#search-db .form-control {
    font-size: 12px;
    font-weight: 500;
    /* border: 0.5px solid #ccc; */
    /* border-radius: 8px; */
    padding: 10px;
    height: auto;
}

#search-db .text-success {
    color: #15C39A !important;
}


#search-db .c-red {
    color: #E21E1E !important;
}

#search-db .c-yellow {
    color: #FCD000 !important;
}

#search-db .c-black {
    color: #000000 !important;
}

#search-db .c-yash {
    color: #7D7D7D !important;
}

 #search-db .btn-sm,
#search-db .btn {
    padding: 0.5rem 0.65rem !important;
}

#search-db .d-grid .btn {
    padding: 0.45rem 0.2rem !important;
    font-weight: 600 !important;
    font-size: 8px !important;

}

#search-db .bg-l-green {
    background: #F6FFFD !important;
}

#search-db .bg-l-green-b {
    border: 1px solid #79e6cc;
}

#search-db .bg-l-yash-b {
    border: 1px solid #c6c6c6;
}

#search-db .bg-l-yash-b-l {
    border: 1px solid rgb(21 195 154 / 33%);
}

#search-db .bg-l-green-ext {
    background: #F6FFFD !important;
}

#search-db .bg-l-green-b {
    border: 1px solid #79e6cc;
}

#search-db .small-ratings i {
    color: #cecece;
}

#search-db .rating-color {
    color: #fbc634 !important;
}

#search-db .border-radius-4 {
    border-radius: 4px;
}

#search-db .border-right {
    border-right: 1px solid #ccc;
}

.row {
    --bs-gutter-x: 1rem;
}

/* SECTIONS */
#search-db .A-SEC {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px #0000000D;
    /* padding-bottom: 5px; */
}

#search-db .A-SEC-ONE {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 14px #0000000D;
}

#search-db .A-SEC-ONE .price h6 {
    margin: 0;
    font-size: 12px;
    color: #00B97C;
    font-weight: 600;
}

#search-db .A-SEC-ONE .price span {
    font-size: 10px;
    color: #555656;
}

/* BUTTONS */
#search-db .btn {
    font-size: 12px;
    font-weight: 600;
}

/* ***** Total Sec ****** */
#search-db .total-sec .btn-secondary,
#search-db .total-sec .btn-secondary:focus {
    box-shadow: none;
    background: #fff !important;
    /* border: 1px solid #5c636a !important; */
    color: #000;
}

#search-db .total-sec .dropdown-menu,
#search-db .total-sec .dropdown-item {
    font-size: 12px;
}

#search-db .total-sec .dropdown-toggle i {
    /* margin-left: 5px; */
}

#search-db .total-sec .dropdown-toggle::after {
    display: none;
}

/* #search-db .total-sec .dropdown-toggle:hover i{
transform: rotate(180deg);
} */

#search-db .total-sec .btn {
    font-size: 11px;
}

#search-db .btn-outline-success {
    border-color: #15C39A;
    color: #02c798;
}

#search-db .btn-outline-success:hover {
    color: #fff;
}


/* BUTTON BACKGROUND COLOURS */
#search-db .btn-primary {
    background-color: #E21E1E;
    border: 1px solid #E21E1E;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
}
#search-db .btn-primarys{
    background-color: #15c39a;
    border:1px solid #15c39a;
    color:#fff
}

#search-db .btn-primary:hover {
    background-color: #E21E1E;
    border-color: #E21E1E;
}

#search-db .btn.btn-search {
    font-weight: 600;
    color: #fff;
}
#search-db .btn-check:focus+.btn-primary,
#search-db .btn-primary:focus,
#search-db .btn-primary:active:focus {
    background-color: #E21E1E;
    border-color: #E21E1E;
    box-shadow: 0 0 0 0.25rem rgb(237 94 94 / 20%);
}

#search-db .btn-outline-secondary {
    color: #000;
    border-color: #363d48;

}

#search-db .btn-outline-secondary:hover {
    color: #fff;
    background-color: #363d48;
}

#search-db .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(226 30 30 / 10%);
    border-color: #E21E1E;
}

#search-db .sec-tabs .input-group input:focus {
    border-right: 1px solid #E21E1E;
}

#search-db .btn-check:focus+.btn-outline-success,
#search-db .btn-outline-success:focus {
    box-shadow: 0 0 0 0.20rem rgb(25 135 84 / 20%);
}

#search-db .btn-check:focus+.btn-dark,
#search-db .btn-dark:focus,
#search-db .btn-check:focus+.btn-outline-secondary,
#search-db .btn-outline-secondary:focus,
#search-db .btn-outline-secondary.active:focus {
    box-shadow: 0 0 0 0.2rem rgb(167 171 175 / 25%);
}

#search-db .btn-check:active+.btn-dark:focus,
#search-db .btn-check:checked+.btn-dark:focus,
#search-db .btn-dark.active:focus,
#search-db .btn-dark:active:focus,
#search-db .show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(167 171 175 / 25%);
}

#search-db .btn-outline-success .c-primary {
    color: #E21E1E !important;
}

#search-db .d-d {
    display: grid;
}

/* Section Tabs */
#search-db .sec-tabs .btn.datepicker {
    padding: 4px 7px 4px 4px !important;
}

#search-db .sec-tabs .nav-pills .nav-link.active,
#search-db .nav-pills .show>.nav-link {
    background-color: #FFF6F6;
    color: #E21E1E;
    font-weight: 500;
    border-radius: 0;
    font-size: 12px;
}

#search-db .sec-tabs .nav-pills {
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
}

#search-db .sec-tabs .nav-pills .nav-link.active,
#search-db .sec-tabs .nav-pills .show>.nav-link,
#search-db .sec-tabs .nav-pills .nav-link {
    padding: 6px 32px 6px 32px;
}

#search-db .sec-tabs .nav-link {
    color: #1B1B28;
    font-weight: 500;
    font-size: 12px;
}

#search-db .sec-tabs .btn-light,
#search-db .btn-light {
    border: 0.5px solid #363d48;
    background-color: #fff;
}

#search-db .sec-tabs .btn-light:hover,
#search-db .btn-light:hover {
    background: #363d48 !important;
    color: #fff;
}

#search-db .btn-success {
    border: 0.5px solid #15C39A;
    background-color: #15C39A;
}

#search-db .btn-success:hover {
    color: #fff;
}

#search-db .sec-tabs .btn-dark {
    background: #363d48;
    border-color: #363d48;
}

#search-db .sec-tabs .btn-dark:hover {
    background-color: #363d48;
    border-color: #363d48;
}

#search-db .sec-tabs .badge,
#search-db .sec-tabs .btn-dark .badge {
    border: 1px solid #ccc !important;
    background: #FFF6F6 !important;
    color: #000 !important;
    border-radius: 50%;
    padding: 4px;
    width: 40px;
    height: 20px;
    text-align: center;
    margin: 0 auto;
    line-height: 10px;
}

#search-db .sec-tabs .deviation,
#search-db .sec-tabs .calender,
#search-db .sec-tabs .from-location,
#search-db .sec-tabs .locationTo {
    padding-left: 30px;
    background-size: 18px 18px !important;
    background-position: 6px !important;
}

#search-db .sec-tabs .locationTo {
    background: url('../../images/location.svg') no-repeat left;
}

#search-db .sec-tabs .deviation {
    background: url("../../images/deviation.svg") no-repeat left;
}

#search-db .sec-tabs .calender {
    background: url("../../images/calender.svg") no-repeat left;
}

#search-db .sec-tabs .from-location {
    background: url("../../images/location.svg") no-repeat left;
}

/* .sec-tabs input:focus-visible {
    outline: 1px solid #9f9f9f;
}
*/

#search-db .sec-tabs span.input-group-text {
    padding: 0.35rem 0.45rem;
}

#search-db .sec-tabs .input-group button {
    border-top: 1px solid #ccc;
    border-left: 0px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #ccc;
}

#search-db .sec-tabs .input-group button:hover {
    color: #4a4141;
    background-color: #e0e0e0;
}

#search-db .sec-tabs .input-group .input-group-text {
    background-color: #fff;
}

#search-db .sec-tabs .input-group button:nth-child(3) {
    border-right: 1px solid #ccc;
}

#search-db .sec-tabs .input-group input {
    border-right: 1px solid #fff;
}

#search-db .sec-tabs .input-group .input-group-text {
    border-left: 0px solid #fff;
}

#search-db .sec-tabs .input-group input {
    margin-bottom: 0px;
}

#search-db .sec-tabs .btn .ser.left {
    margin-right: 0.6rem;
}

/* *** Sec Recent ** */
.sec-recent {}

#search-db .sec-recent ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#search-db .sec-recent li {
    border-bottom: 1px solid #ccc;

}

#search-db .sec-recent li:last-child {
    border-bottom: none;
}



/* SEC CARD */
#search-db .sec-card {
    font-size: 12px;
}

#search-db .sec-card hr {
    margin: 2px 0;
    background-color: #a7a4a4;
    height: 1px;
    opacity: 0.11;
}

#search-db .sec-card .d-grid .btn {
    font-size: 8px !important;
}

#search-db .sec-card .btn {
    font-weight: 400;
}

#search-db .sec-card .grid-name {
    min-width: 140px;
    width: 140px;
}


#search-db .sec-card .grid-name.grid,
#search-db .sec-card .grid-name.grid h5,
#search-db .sec-card .grid-name h5.grid,
#search-db .sec-card .grid-name h5 {
    min-width: auto !important;
    width: 100% !important;
    max-width: auto !important;
}

#search-db .sec-card .grid-name h5,
#search-db .deals .grid-name h5,
#search-db .filter-half-sec .grid-name h5,
#search-db .total-info .grid-name h5,
#search-db .crush-details .grid-name h5,
#search-db .crush-details .grid-name h2 {
    color: #000;
    font-weight: 700;
    display: block;
    font-size: 10px;
    margin-bottom: 0px;
}

#search-db .sec-card .grid-name h5,
#search-db .filter-half-sec .grid-name h5,
#search-db .total-info .grid-name h5 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#search-db .sec-card .grid-name h5 {
    max-width: 130px;
    min-width: 118px;
}



#search-db .sec-card .grid-name span.axel,
#search-db .crush-details .grid-name span.axel,
#search-db .crush-details .grid-name span,
#search-db .filter-half-sec .grid-name span.axel {
    color: #000000;
    font-size: 10px;
}

#search-db .sec-card img.truck {
    width: 14px;
    height: 64px;
}


#search-db .sec-card .grid-name.grid h5 {
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    text-align: left;

}

/* PRICE TABLE */
#search-db .sec-card table {
    border-collapse: separate;
    border-spacing: 0;
}

#search-db .sec-card table tr th,
#search-db .sec-card table tr td {
    border-right: 1px solid #c2dae2;
    border-bottom: 1px solid #c2dae2;
    padding: 0px;
}

#search-db .sec-card table tr th:first-child,
#search-db .sec-card table tr td:first-child {
    border-left: 1px solid #c2dae2;
}

#search-db .sec-card table tr th {
    /* background: #d2e9ff4f; */
    border-top: 1px solid #c2dae2;
    text-align: left;
}

#search-db .sec-card table tr {
    background: #d2e9ff4f;
}

/* SEC TABLE ROUNDED CORNERS*/
#search-db .sec-card table tr:first-child th:first-child {
    border-top-left-radius: 4px;
}

#search-db .sec-card table tr:first-child th:last-child {
    border-top-right-radius: 4px;
}

#search-db table tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
}

#search-db .sec-card table tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
}

#search-db .price-table .table> :not(caption)>*>* {
    padding: 0.2rem 0.2rem;
}

#search-db .table-main-text {
    color: #000000;
    font-size: 10px;
    font-weight: 600;
}

#search-db .table-main-text span.table-text-fade {
    color: #111;
    font-size: 10px;
    font-weight: 500;
}


/* ***** GRID SCROLL BAR *** */

#search-db .scrollbar {
    height: 75vh;
}


#search-db .scrollbar {
    width: 100%;
    background: transparent;
    overflow-y: scroll;
    overflow-x: hidden;
}

#search-db .force-overflow p,
#search-db .force-overflow-one p {
    text-align: justify;

}

#search-db #style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(202, 212, 225);
    background-color: #ccc;

}



#search-db #style-3::-webkit-scrollbar {
    width: 4px;
    background-color: #f1f5f9;
    display: none;
}

#search-db #style-3::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;

}

#search-db #style-3:hover::-webkit-scrollbar {
    display: block;

}



/* TAB RECENT  [DEAL] */

#search-db .deals .grid-name span.date {
    letter-spacing: -0.2px;
}

#search-db .deals .list-group-item {
    border-bottom: 1px solid rgba(0, 0, 0, .125) !important;
    border: 0;
    border-radius: 0;
}

#search-db .deals li.list-group-item:hover,
#search-db .filter-half-details li.list-group-item:hover {
    background-color: rgb(21 195 154 / 6%) !important;

}

/*
.deals li.list-group-item:first-child,
.filter-half-details li.list-group-item:first-child {
    background-color: #FFF6F6;
} */
#search-db .deals li.list-group-item.active,
#search-db .filter-half-details li.list-group-item.active {
    background-color: rgb(21 195 154 / 6%) !important;
    border-left: 2px solid #15c39a;
}

#search-db .starrate {
    position: relative;
    z-index: 1;
}

#search-db .starrate div {
    display: block;
    text-align: right;
}

#search-db .deals .grid-name span.axel .elli,
#search-db .filter-half-sec .grid-name span.axel .elli,
#search-db .total-info .grid-name span.axel .elli {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#search-db .total-info .grid-name span.axel .elli {
    width: 65px;
}

#search-db .deals .grid-name span.axel .elli,
#search-db .filter-half-sec .grid-name span.axel .elli,
#search-db .total-info .grid-name span.axel .elli {
    max-width: 120px;
}


/* FILTER HALF SECTION */
#search-db .filter-half-sec .flt-icons .list-group-item,
#search-db .filter-full-sec .flt-icons .list-group-item {
    border: none;
    padding: 0;
}

#search-db .filter-half-details .list-group-item {
    border-bottom: 1px solid rgba(0, 0, 0, .125) !important;
    border: 0;
    border-radius: 0;
}

#search-db .filter-half-sec hr,
#search-db .filter-full-sec hr {
    border-bottom: 1px solid #c3c3c3;
    opacity: .10;
    margin: 0;
}

#search-db .filter-half-details .list-group-item {
    padding: 0.5rem 0.5rem;
}

#search-db .filter-half-details .filter-rates h1 {
    font-size: 10px;
    color: #292a2a;
    font-weight: 500;
    margin-top: 5px;
}

/* #search-db .filter-half-details .rate-details .table>:not(caption)>*>*,
#search-db .table>:not(caption)>*>* {
    padding: 0.3rem;
} */

#search-db .filter-half-details .rate-details table .btn-sm {
    padding: 0.35rem 0.2rem !important;
}

/* ***** LIGHT BOX */
#search-db .lb-data .lb-number {
    display: none !important;
}

/* FILTER FULL SECTION */
#search-db .title {
    margin-top: 0;
    margin-bottom: 0.1rem;
}

#search-db .align-v-center {
    display: flex;
    align-items: center !important;
}

#search-db .image-wrapper.circular-image {
    width: auto;
    display: block;
}

#search-db .image-wrapper.circular-image .image {
    width: 55px;
    height: 55px;
    border-radius: 12%;
    object-fit: cover;
    object-position: center;
}

#search-db .title-text {
    font-size: 12px;
    font-weight: 500;
    color: #4f44cf;
    margin: 6px 0px;
}

/* .filter-full-sec table.full-details{
margin-bottom: 0;
} */

#search-db .filter-full-sec table.full-details div.main {
    display: inherit;
    color: #7D7D7D;
    font-size: 10px;
    font-weight: 500;
    margin: 0;
    word-break: break-all;
}

#search-db .filter-full-sec table.full-details span {
    display: block;
    color: #000;
    font-size: 10px;
    font-weight: 600;
    margin: 0;
}

/* .filter-full-sec table.full-details td:first-child {
    width: 50% !important;
} */

#search-db .filter-full-table table {
    margin-bottom: 5px;
}

#search-db .v-divider {
    border-right: 1px solid #ccc;
    height: 95.3%;
    position: static;
}

/* ********** SECTION HALF AND FULL */

#search-db .res-info-mr-ml {
    margin-left: 15px;
    margin-right: 15px;
}

#search-db .lable-border {
    background-color: #ccc;
    border: 1px solid #000066;
    padding: 5px;
}


#search-db .total-info .form-control {
    padding: 0.63rem 0.25rem;
}

#search-db .wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
}

#search-db .wrapper .option {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 3px;
    border-radius: 6px;
    cursor: pointer;
    padding: 5px 10px;
    transition: all 0.3s ease;
    background: #fff;
    border: 2px solid #d9d9d9;
}

#search-db .wrapper .option .dot {
    height: 11px;
    width: 11px;
    background: #fff;
    border-radius: 50%;
    position: relative;
    border: 1px solid #fff;
    outline: #959595 solid 1px;
}

#search-db .wrapper .option .dot::before {
    position: absolute;
    content: "";
    top: -1px;
    left: -1px;
    width: 12px;
    height: 12px;
    background: #15C39A;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
    border: 1px solid #fff;
    outline: #15C39A solid 1px;
}

#search-db input[type="radio"] {
    display: none;
}

/* #option-1:checked:checked ~ .option-1,
  #option-2:checked:checked ~ .option-2 {
    border-color: #0f117a;

    background: #0f117a;
  } */

#search-db #option-1:checked:checked~.option-1 .dot,
#search-db #option-2:checked:checked~.option-2 .dot {
    background: #fff;
}

#search-db #option-1:checked:checked~.option-1 .dot::before,
#search-db #option-2:checked:checked~.option-2 .dot::before {
    opacity: 1;
    border: 1px solid #fff;
    outline: #15C39A solid 1px;
    transform: scale(1);
}

#search-db .wrapper .option span {
    font-size: 12px;
    padding-left: 6px;
    color: #808080;
    text-transform: uppercase;
}

#search-db #option-1:checked:checked~.option-1 span,
#search-db #option-2:checked:checked~.option-2 span {
    color: #0c0b0b;
    padding-left: 6px;
}

/* ********** TABS HHEIGHTS */
#search-db .tab-content>.active {
    min-height: 650px;
}

#search-db .deals.recent-tab-height {
    height: 600px;
}

#search-db .deals.saved-tab-height {
    height: 650px;
}

#search-db .filter-half-details.results-tab-height {
    height: 680px;
}

/* ******* GRID VIEW ***** */
#search-db .grid-icons .btn {
    padding: 0.55rem 0.55rem !important;
    border: none;
}

#search-db .grid-icons .btn i {
    font-size: 11px;
}

#search-db .grid-icons .btn.active {
    background: #fff;
    color: #E10909;
}

#search-db .grid-icons .btn-check:focus+.btn,
.grid-icons .btn:focus {
    outline: 0;
    box-shadow: none;
}

/* VIEW PORT HEIGHT */

/*#search-db .f-vh-100 {*/
/*    height: 100%;*/
/*}*/

/*#search-db .f-vh-85 {*/
/*    height: 85vh;*/
/*}*/

/*#search-db .f-vh-75 {*/
/*    height: 75vh;*/
/*}*/

/*@media only screen and (min-width: 1250px) {*/
/*    #search-db .f-vh-66 {*/
/*        height: 66vh;*/
/*    }*/
/*}*/


#search-db .sidebar {
   height: calc(98vh - 140px);
}
#search-db .g-map {
    height: calc(100vh - 100px);
}

/* ********************************************** */


#search-db .flex-container {
    /* display: flex; */
    position: relative;
}

#search-db .sidebar {
    /* flex-grow, flex-shrink, flex-basis   */
    flex: 0 1 100%;
    padding: 0 0px 0 0;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 250px ;
}
#search-db .flex-container
#search-db .content {
    flex: 1 1;
    padding: 15px;
}

#search-db .scrollbox {
    padding: 0px 0px 0px 0px;
}

#search-db .scrollbox-1 {
    padding: 0px 0px 0px 0px;
}

#search-db .scrollbox,
#search-db .scrollbox-1 {
    overflow-x: hidden;
    overflow: overlay;
    visibility: hidden;
}

#search-db .scrollbox-inner,
#search-db .scrollbox:hover,
#search-db .scrollbox-1:hover {
    visibility: visible;
    overflow-x: hidden;
}

#search-db #style-1::-webkit-scrollbar {
    width: 3px;
    position: absolute;
}

#search-db #style-1::-webkit-scrollbar-track {}

#search-db #style-1::-webkit-scrollbar-thumb {
    background: #868686;
    border-radius: 5px;
}


/* CAROUSEL */

#search-db #myCarousel,
#search-db #myCarousel-one {}

#search-db #myCarousel .card-body,
#search-db #myCarousel-one .card-body {
    padding: 3px;
    margin: 3px;
}

#search-db #myCarousel .card-body img,
#search-db #myCarousel-one .card-body img {
    width: 100% !important;
    height: 42px;
}


#search-db #myCarousel .carousel-control-next,
#search-db #myCarousel .carousel-control-prev,
#search-db #myCarousel-one .carousel-control-next,
#search-db #myCarousel-one .carousel-control-prev {
    width: 10%;
}

@media (max-width: 768px) {
    #search-db .carousel-inner .carousel-item>div:first-child {
        display: block;
    }

}

#search-db .carousel-inner .carousel-item.active,
#search-db .carousel-inner .carousel-item-start,
#search-db .carousel-inner .carousel-item-next,
#search-db .carousel-inner .carousel-item-prev {
    display: flex;
}

@media (min-width: 768px) {

    #search-db .carousel-inner .carousel-item-right.active,
    #search-db .carousel-inner .carousel-item-next,
    #search-db .carousel-item-next:not(.carousel-item-start) {
        transform: translateX(74.3%) !important;
    }

    #search-db .carousel-inner .carousel-item-left.active,
    #search-db .carousel-item-prev:not(.carousel-item-end),
    #search-db .active.carousel-item-start,
    #search-db .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(-74.3%) !important;
    }

    #search-db .carousel-item-next.carousel-item-start,
    #search-db .active.carousel-item-end {
        transform: translateX(0) !important;
    }

    #search-db .carousel-inner .carousel-item-prev,
    #search-db .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(-74.3%) !important;
    }


}

/* ***** TOTAL INFO ******************* */

#search-db .total-info .nav-tabs {
    border-bottom: none;
}

#search-db .total-info .right-icons {
    position: relative;
}

#search-db .total-info .right-icons .links {
    position: absolute;
    top: -36px;
    right: 0;
}

#search-db .total-info .left-icons {
    position: relative;
    top: 0px;
    left: -23px;
}

#search-db .total-info .right-icons .list-group-item {
    border: none;
    padding: 0.5rem;
}

#search-db ul.rightside-icons,
#search-db ul.leftside-icons {
    list-style: none;
}

#search-db ul.rightside-icons li {
    float: left;
    padding: 9px 10px;
}

#search-db ul.leftside-icons li {
    float: left;
    padding: 5px;
}

#search-db ul.leftsideside-icons li {
    float: left;
    padding: 5px 10px;
}

#search-db ul.rightside-icons li a {
    text-decoration: none;
    color: #000000;
    display: flex;
    padding-top: 2px;
}

#search-db ul.rightside-icons li a:hover {
    color: #E21E1E;
}

#search-db .total-info nav {
    border-bottom: 1px solid #dee2e6;
    padding-top: 4px;
}

#search-db .total-info .nav-tabs .nav-link {
    padding: 0.3rem 0.5rem;
    font-weight: 500;
    color: #000000;
}

#search-db .total-info .nav-tabs .nav-link.active {
    color: #E21E1E;
    background-color: #FFF6F6;
    border-color: #eebebe #eebebe #fff;
}



/* *****CHECKBOX **** */

/* Checkbox styles */
#search-db label.checkbox {
    padding-left: 1.25rem;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    margin-left: 20px;
}

#search-db label.checkbox input[type=checkbox] {
    position: absolute;
    opacity: 0;
}

#search-db label.checkbox input[type=checkbox]:checked~span {
    color: #FFFFFF;
    border: 2px solid #0537ff;
    background: #0537ff url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+) 50% 40% no-repeat;
}

#search-db label.checkbox span {
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 0px;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid #969696;
    font-size: medium;
}




/* DESKTOP MODE */
@media (min-width: 1400px) {
    #search-db .container {
        max-width: 1920px;
    }

    #search-db .new-dash {
        text-align: left;

    }

    /*#search-db .f-vh-100 {*/
    /*    height: 100vh;*/
    /*}*/

    #search-db .pe-00 {
        padding-right: 0 !important;
    }

    #search-db .res-info-mr-ml {
        margin-left: 0px;
        margin-right: 15px;
    }

    #search-db .xl-p-l-15 {
        padding-left: 15px;
    }

    #search-db .xl-p-l-1 {
        padding-left: 1rem;
    }

    #search-db .xl-p-l-2 {
        padding-left: 2rem;
    }

    #search-db .xl-p-r-1 {
        padding-right: 1rem;
    }
    #search-db .xl-p-r-2 {
        padding-right: 2rem;
    }


#search-db .flex-container {
    display: flex;
    position: relative;
}

}

/* TAb mode */

@media only screen and (max-width: 1199px) {
    /*#search-db .f-vh-85 {*/
    /*    height: 85vh !important;*/
    /*}*/

    #search-db .ser-field-mb-4 {
        margin-bottom: 5px !important;
    }

    #search-db .deviation span.input-group-text {
        padding: 0.35rem 0.35rem;
    }

    #search-db .t-m-t-15 {
        margin-top: 15px !important;
    }



}

/* MOBILE MODE */
@media only screen and (max-width: 600px) {

    #search-db .ser-field-mb-4 {
        margin-bottom: 5px !important;
    }

    /*#search-db .f-vh-85 {*/
    /*    height: 35vh !important;*/
    /*}*/

    #search-db .m-m-b-15 {
        margin-bottom: 15px !important;
    }

    #search-db .m-m-b-4 {
        margin-bottom: 4px !important;
    }

    #search-db .m-m-t-15 {
        margin-top: 15px !important;
    }

    #search-db .m-m-l-15 {
        margin-left: 15px !important;
    }

    #search-db .total .float-end {
        float: left !important;
    }

    #search-db .pe-1 {
        padding-right: 0.75rem !important;
    }

    #search-db .sec-tabs .nav-pills .nav-link.active,
    #search-db .sec-tabs .nav-pills .show>.nav-link,
    #search-db .sec-tabs .nav-pills .nav-link {
        padding: 6px 25px 6px 25px;
    }

    #search-db .filter-half-details .rate-details table .btn-sm {
        padding: 0.35rem 0.35rem !important;
    }

    #search-db .sec-left-total {
        float: none;
        width: 100%;
    }

    #search-db .sec-right-total {
        float: none;
        width: 100%
    }

    #search-db ul.rightside-icons li {
        padding: 13px 1px;
    }
}


@media only screen and (max-width: 1024px) and (min-width: 768px) {
    #search-db .pe-1 {
        padding-right: 0.75rem !important;
    }

    #search-db .t-m-b-15 {
        margin-bottom: 15px !important;
    }

}


@media only screen and (min-width: 360px) and (max-width: 740px) {

    #search-db .ser-field-mb-4 {
        margin-bottom: 5px !important;
    }

    #search-db .deviation span.input-group-text {
        padding: 0.35rem 0.35rem;
    }

    #search-db .pe-1 {
        padding-right: 0.75rem !important;
    }

    #search-db .t-m-b-15 {
        margin-bottom: 15px !important;
    }

    #search-db .sec-left-total {
        float: none;
        width: 100%;
    }

    #search-db .sec-right-total {
        float: none;
        width: 100%
    }

    #search-db .m-m-r-15 {
        margin-right: 15px !important;
    }

    #search-db .m-m-l-15 {
        margin-left: 15px !important;
    }

}


/* ********* ROUNDED TABLE BORDER ********** */

#search-db .r-table .scrollbox {
    padding: 0px 0px 0px 0px;
    overflow: overlay;
}

#search-db .tablerounededCorner .table-bordered>:not(caption)>*>*{
    border-width: 0 0px;
}
#search-db .tablerounededCorner .table>:not(caption)>*>*{
box-shadow:none
}

/* #search-db .tablerounededCorner .table>:not(caption)>*>* {
    padding: 0.5rem !important;
} */

#search-db .tablerounededCorner {
    border: 0px solid #EAEAEA;
    background-color: #fff;
    border-radius: 8px;
}

#search-db .roundedTable {
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    margin: 0;
}

#search-db .roundedTable th:last-child,
#search-db .roundedTable td:last-child {
    width: 80px;

}

#search-db .roundedTable th,
#search-db .roundedTable td {
    border-bottom: 1px solid #EAEAEA;
    color: #000;
    vertical-align: middle;
    font-weight: 500;
}

#search-db .roundedTable th {
    text-align: left;
    background: #F6FFFD;
    padding: 0.7rem 0.5rem !important;
}

#search-db .roundedTable tr:last-child td {
    border-bottom: none;
}

#search-db .roundedTable th a,
#search-db .roundedTable td a {
    color: #000;
    text-decoration: underline;
}

#search-db .roundedTable th .text-primary,
#search-db .roundedTable td .text-primary {
    color: #005081;
}

#search-db .roundedTable td .text-success {
    color: #15C39A !important;
}

#search-db .roundedTable th .dropdown a,
#search-db .roundedTable td .dropdown a {
    text-decoration: none;
}

#search-db .roundedTable th .dropdown .dropdown-item,
#search-db .roundedTable td .dropdown .dropdown-item {
    padding: 0.35rem;
}

#search-db .roundedTable th .dropdown .dropdown-menu,
#search-db .roundedTable td .dropdown .dropdown-menu {
    min-width: 8rem;
}

#search-db .roundedTable a.btn-action {
    background-color: #e9e9e9;
    border: none;
    color: #848484;
    padding: 6px 9px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
}

#search-db .roundedTable a.btn-action:hover {
    background-color: #d1d1d1;
    color: #000;
}

#search-db .roundedTable .w-100 {
    min-width: 105px !important;
}

#search-db .btn.map-view:hover {
    color: #fff !important;
}

#search-db .map-view img,
#search-db .btn.map img  {
    width: 17px;
    margin-left: 8px;
}

#search-db .map-grid {
    position: relative;
}

#search-db .product-pop .product-pop-label {
    left: auto;
    right: 18px;
    top: 7px;
    position: absolute;
    padding: 0.5rem 0.65rem !important;
}
#search-db .product-popup .product-pop-label {
    left: auto;
    right: 18px;
    top: 0px;
    position: absolute;
    padding: 0.2rem 0.65rem !important;
}


#search-db .product-pop .btn.map:hover {
    background-color: #fff;
    color: #bd0303 !important;
}
#search-db .product-popup .btn.map:hover {
    background-color: #fff;
    color: black !important;
}

#search-db .grid-list img{
width: 16px;
height: 16px;
}

#search-db .grid-list #list{
    /* border-right: 1px solid #F3F3F3; */
}

.book-hide{
    display: none !important;
}
.padding-style{
    padding-left: 1px;
    padding-right: 0px;
}
.font-bold{
    color: black !important;
    border: 1px solid lightgray !important;
}
