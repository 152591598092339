.page-content-wrapper {
    margin: 0 -10px;
}

.api-container {
    width: 74%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.bg-gray {
    background-color: #eee;
}

.app-search {
    position: relative;
}

.app-search button {
    position: absolute;
    top: 3.5px;
    left: 5px;
    display: block;
    color: #373935;
    font-size: 11px;
    border: none;
    background-color: transparent;
    /* border-right: 1px solid #000 !important; */
    padding-right: 10px !important;
}

.app-search input {
    padding-left: 30px !important;
}

.accor {
    display: none;
}

.sidebar-list {
    padding: 0px;
    list-style: none;
    letter-spacing: 0.5px;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.sidebar-list li {
    border-bottom: 1px solid #ddd;
    margin: 0 auto 7px;
    padding: 5px 0;
}

.sidebar-list li a:hover, .sidebar-list li a.active, .sidebar-list li:hover {
    color: #007bff;
}

.accordion.plus.collapsed::after {
    display: none;
}

.accordion.plus.collapsed:hover::before {
    content: '\f067';
    font-family: 'FontAwesome';
    position: absolute;
    right: 0px;
}

.accordion.plus.collapsed:hover {
    cursor: pointer;
}

.accordion.plus::after {
    content: '\f067';
    font-family: 'FontAwesome';
    position: absolute;
    right: 0px;
}

.accordion.plus.collapsed.accor-plus:before {
    content: '\f068';
    font-family: 'FontAwesome';
    position: absolute;
    right: 0px;
}

ul ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.sidebar-list li a {
    /*color: #000;*/
}

.sidebar-list li a:hover, .sidebar-list li a.active, .sidebar-list li:hover {
    color: #007bff;
}

.sidebar-list li a {
    color: #000;
}

* {
    outline: none !important;
}

*, ::after, ::before {
    box-sizing: border-box;
}

li {
    text-align: -webkit-match-parent;
}

ul ul {
    list-style-type: circle;
}

ul {
    list-style-type: disc;
}

.sidebar-list {
    padding: 0px;
    list-style: none;
    letter-spacing: 0.5px;
}

ul {
    list-style-type: disc;
}

.right-content h1 {
    line-height: 40px;
    font-size: 26px;
    font-weight: 500;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 auto 7px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.alert {
    position: relative !important;
    border: 0 !important;
}

.alert {
    padding: 0.5rem 1rem !important;
    background-color: #fdf5d9 !important;
    border-radius: 4px !important;
}

.right-content h3 {
    line-height: 30px;
    font-size: 20px;
    font-weight: 500;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

blockquote {
    margin: 1em 2.5em !important;
}

blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
}

blockquote > pre {
    background-color: #eee;
    padding: 5px;
}

pre {
    tab-size: 4;
    counter-reset: linecounter;
    padding: 0;
    color: #fff !important;
    background-color: #000 !important;
    font-size: 100% !important;
    letter-spacing: 0.5px;
    font-family: 'Montserrat', sans-serif !important;
}

pre {
    /*display: block;*/
    /*font-family: monospace;*/
    white-space: pre;
    margin: 1em 0px;
}

.method-detail {
    background-color: #fdf5d9;
    border-radius: 4px;
    padding: 14px 14px 0 14px;
    display: inline-block !important;
    width: 100% !important;
}

p.key {
    float: left;
    clear: left;
}

p.value {
    float: right;
}

table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}

thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.table-ul tr td, .table-ul tr th {
    border: 1px solid #ddd;
}

th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
}

.nav-tabs {
    border: 0px solid #dee2e6 !important;
    border-radius: 0px !important;
}

.nav-tabs .nav-item {
    border: none !important;
}

.nav-tabs .nav-item {
    border-right: 1px solid #ddd;
    color: #000 ;/*!important*/
}

.right-content .nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #000 !important;
    border: none !important;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
}

.object-content {
    background-color: #000 !important;
}

.icon-container, .array-key {
    display: none !important;
}

.array-key ~ span {
    display: none !important;
}

.object-key-val, .variable-row {
    border: 0px !important;
}

/*.object-key-val:first-child span:first-child  span {*/
/*}*/
.object-key-val > span > span {
    cursor: text !important;
}

.react-json-view, .react-json-view:hover, .react-json-view > * {
    cursor: text !important;
}

.api-container * a {
    text-decoration: none !important;
    background-color: transparent !important;
}

.api-container * a:hover {
    outline: 0;
    text-decoration: none;
}

.api-container * a:hover {
    color: #0056b3;
    text-decoration: underline;
}

.api-container * td {
    padding: auto !important;
    margin: auto !important;
}

.downArrow::after {
    content: '\f078';
    font-family: 'FontAwesome';
    position: absolute;
    right: 30px;
}

.upArrow::after {
    content: '\f077';
    font-family: 'FontAwesome';
    position: absolute;
    right: 30px;
}

