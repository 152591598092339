.master-data section {
    padding: 10px;
}

.nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
    background-color: #404e67 !important;
    font-weight: bold;
}

.tab-content>.active {
    display: block;
}

.master-data * a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.master-data .dropzone {
    cursor: pointer;
}

.master-data .dropzone-main {
    border: 2px dashed #ddd;
    padding: 12px 20px;
}

.master-data .drop-browse {
    border: 1px solid #007bff;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 5px;
    color: #007bff;
    position: absolute;
    /*right: 15%;
    top: 15%;*/
}
.browse-btn{
    margin-top: -5px;
}

.normal-table table {
    width: 100%;
}

.normal-table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.normal-table tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

.normal-table.error-table table th {
    letter-spacing: .1em;
    background: #e48312;
    color: #fff;
}

.normal-table table th, .normal-table table td {
    padding: 0.4em;
    text-align: left;
}
.normal-table tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

 .normal-table table .billing {
        min-width: 140px;
      }
      .normal-table table .description {
        min-width: 190px;
      }
      .invoice-process textarea {
        width: 100%;
      }
  section.bottom {
    box-shadow: none !important;
    margin: 0 auto !important;
    padding: 15px 0px;
  }
  .normal-table {  
    overflow-y: scroll;
  }
  .normal-table table {
    width: 100%;
  }
  .normal-table table th {
    letter-spacing: .1em;
    background: #00b5dd;
    color: #fff;
  }
  .normal-table.error-table table th {
    letter-spacing: .1em;
    background: #e48312;
    color: #fff;
  }
  .normal-table.error-table .table-striped tbody tr:nth-of-type(2n+1), .normal-table.error-table .thead-default th {
    background-color: #f5d9cc;
  }
  .normal-table.error-table .table-striped tbody tr:nth-of-type(even) {
    background-color: #faede7;
  }
  .normal-table.error-table .table-bordered td, .normal-table.error-table .table-bordered th {
    border: 1px solid #fff;
  }


  .normal-table table th, .normal-table table td {
    padding: .4em;
    text-align: left;
  }